var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.pageDecorImage ? 'bg-no-repeat bg-right-bottom bg-contain' : '',style:(_vm.backgroundImageStyle)},[(_vm.isLoading)?_c('Spinners',{staticClass:"py-8"}):(_vm.sessions.length != 0)?_c('div',{key:"has-sessions",staticClass:"container py-8 session-schedule",attrs:{"id":"session-tab--container"}},[(_vm.ifMultipleTab)?_c('b-tabs',{directives:[{name:"addbuefytabs",rawName:"v-addbuefytabs"}],ref:"Tabs",attrs:{"position":"is-centered","type":"is-boxed mg-large-tabs nested-b-tabs","destroy-on-hide":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.datesForTabs),function(date){return _c('b-tab-item',{key:date,attrs:{"label":_vm.MgFormatISODateTime(date, 'day-month'),"tabindex":date}},[(_vm.pageSessionConfig.helpText)?_c('b-notification',{directives:[{name:"addbuefynotification",rawName:"v-addbuefynotification"}],staticClass:"max-w-md mx-auto mt-8 content",attrs:{"type":"is-info","has-icon":"","closable":false}},[_vm._v(" "+_vm._s(_vm.pageSessionConfig.helpText)+" ")]):_vm._e(),_c('div',{staticClass:"px-4 flex md:flex-row justify-center items-center"},[_c('svg-calendar',{staticClass:"mt-4 md:mt-0 mr-2 title md:text-4xl md:mb-2"}),_c('h2',{staticClass:"header-sched title text-center has-dark-text mg-font-bold my-8  md:text-2xl text-lg"},[_vm._v(" "+_vm._s(_vm.MgFormatISODateTime(date, "fullday-month"))+" ")])],1),_c('div',{staticClass:"w-auto lg:flex px-4 lg:px-0 m-auto mb-4 lg:flex-row max-w-lg"},[_c('div',{staticClass:"w-full  max-w-35/100 flex lg:flex flex-initial  items-end mb-4 lg:mb-0 mr-4",class:[
                            _vm.searchString || _vm.tags2Category.length
                                ? 'mr-4'
                                : 'mr-0'
                        ]},[_c('b-input',{staticClass:"w-full",attrs:{"lazy":true,"type":"search","id":("session-search-" + _vm._uid),"placeholder":"Search","autocomplete":"off"},model:{value:(_vm.searchString),callback:function ($$v) {_vm.searchString=$$v},expression:"searchString"}}),_c('button',{staticClass:"button is-primary"},[_vm._v(" ​ "),_c('span',{staticClass:"sr-only"},[_vm._v("Search")]),_c('font-awesome-icon',{attrs:{"aria-hidden":"true","icon":"search"}})],1)],1),(_vm.tags2Category.length)?_c('div',{staticClass:"w-full mb-4 lg:mb-0 lg:mr-4 flex-initial"},[_c('b-select',{staticClass:"appearance-none w-full session--filter",attrs:{"placeholder":"Select a category"},model:{value:(_vm.sessionCategoryFilter),callback:function ($$v) {_vm.sessionCategoryFilter=$$v},expression:"sessionCategoryFilter"}},[_c('option',{attrs:{"value":""}},[_vm._v("Select an Activity Type")]),_vm._l((_vm.tags2Category),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2)],1):_vm._e(),(_vm.tagsSessionType.length)?_c('div',{staticClass:"w-full mb-4 lg:mb-0  lg:mr-4 flex-initial",class:_vm.sessionTypeFilter ||
                            _vm.sessionCategoryFilter ||
                            _vm.searchString
                                ? 'mr-4'
                                : 'mr-0'},[_c('b-select',{staticClass:"w-full session--filter",attrs:{"placeholder":"Select a session type"},model:{value:(_vm.sessionTypeFilter),callback:function ($$v) {_vm.sessionTypeFilter=$$v},expression:"sessionTypeFilter"}},[_c('option',{attrs:{"value":""}},[_vm._v("Select a Topic")]),_vm._l((_vm.tagsSessionType),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2)],1):_vm._e(),(_vm.tag3FilterOptions.length)?_c('div',{staticClass:"w-full mb-4 lg:mb-0 lg:mr-4 flex-initial",class:_vm.sessionTypeFilter ||
                            _vm.sessionCategoryFilter ||
                            _vm.searchString
                                ? 'mr-4'
                                : 'mr-0'},[_c('b-select',{staticClass:"w-full session--filter",attrs:{"placeholder":"Select a session type"},model:{value:(_vm.tag3SessionFilter),callback:function ($$v) {_vm.tag3SessionFilter=$$v},expression:"tag3SessionFilter"}},[_c('option',{attrs:{"value":""}},[_vm._v("Virtual Track")]),_vm._l((_vm.tag3FilterOptions),function(option){return _c('option',{key:option,domProps:{"value":option}},[_vm._v(" "+_vm._s(option)+" ")])})],2)],1):_vm._e(),(
                            _vm.searchString ||
                                _vm.sessionTypeFilter ||
                                _vm.sessionCategoryFilter ||
                                _vm.tag3SessionFilter
                        )?_c('div',{staticClass:"flex-1 w-full",staticStyle:{"min-width":"145px"}},[_c('button',{staticClass:"w-auto  mb-4 lg:mb-0 button px-6 ",on:{"click":function($event){return _vm.resetSearch()}}},[_vm._v(" Clear Filters ")])]):_vm._e()]),(_vm.pageConfig.showHelpText)?_c('b-notification',{directives:[{name:"addbuefynotification",rawName:"v-addbuefynotification"}],staticClass:"max-w-md mx-auto mt-4 mb-8 content",attrs:{"type":"is-info","has-icon":"","closable":false}},[(_vm.pageConfig.helpText)?_c('article',{domProps:{"innerHTML":_vm._s(_vm.pageConfig.helpText)}}):_c('ul',{staticClass:"mt-0"},[_c('li',[_vm._v(" You will be able to enter sessions "+_vm._s(_vm.sessionEarlyAccessMinutes)+" minutes ahead of the published start time ")]),_c('li',[_vm._v(" Video meeting rooms will end "+_vm._s(_vm.appointmentExtraMinutes)+" minutes after the published end time ")])])]):_vm._e(),_c('SessionSchedule',{attrs:{"sessionData":_vm.sessionData}})],1)}),1):[_c('div',{staticClass:"flex md:flex-row justify-center items-center"},[_c('svg-calendar',{staticClass:"title md:text-4xl md:mb-2 md:mr-2"}),_c('h2',{staticClass:"header-sched title text-center has-dark-text mg-font-bold my-8"},[_vm._v(" "+_vm._s(_vm.MgFormatISODateTime( _vm.datesForTabs[0], "fullday-month" ))+" ")])],1),_c('SessionSchedule',{attrs:{"sessionData":_vm.sessionData}})]],2):_c('div',{key:"no-sessions",staticClass:"container py-40 flex justify-center"},[_c('span',{staticClass:"notification is-danger max-w-md"},[_c('strong',[_vm._v("Sorry")]),_vm._v(", schedule not available yet. Please check back later. ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }