

































































































































































































































































































































































import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import {
    sessionHelpSessionIsActive,
    sessionHelpSessionIsOver,
    sessionHelpEarlyAccessMinutes
} from "@/services/session-helpers";
import { SessionDataObject, SessionSpeakerObject } from "@/types/interfaces";
import { formatDistance, subMinutes, differenceInSeconds } from "date-fns";

import sessionVuexModule from "@/store/vuex-modules/getSessionData";
const sessionStore = getModule(sessionVuexModule);

import svgVirtual from "@/components/svg/svg-virtual.vue";
import svgInPerson from "@/components/svg/svg-inPerson.vue";
import svgMeetingLocation from "@/components/svg/svg-meetingLocation.vue";
import FavoriteButton from "@/components/shared/FavoriteButton.vue";
import store from "@/store";

export default Vue.extend({
    props: {
        session: {
            required: true,
            type: Object,
            default() {
                return {} as SessionDataObject;
            }
        },
        showDateTime: {
            type: Boolean
        },
        layout: {
            type: String,
            default: ""
        },
        hasNoBio: {
            type: Boolean,
            default: false
        },
        isLoadingFavorites: {
            type: Boolean,
            default: false
        },
        isRelatedSession: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            showingBios: false,
            isSessionActive: false,
            isSessionOver: false,
            sessionLabelInterval: 0,
            timeLabel: "",
            screenSize: 0,
            overideGenericStyle: {
                color: ""
            },
            isTruncated: false,
            isCollapsed: true
        };
    },
    components: {
        svgVirtual,
        svgInPerson,
        svgMeetingLocation,
        FavoriteButton
    },
    computed: {
        routerPath(): boolean {
            return this.$route.name === "Sessions" ||
                this.$route.name === "Home"
                ? true
                : false;
        },

        sessionId(): string {
            const session = this.session;
            let id = this.session.sessionId;

            if (!id && this.session.id) {
                id = this.session.id;
            }

            return id;
        },

        isVideoSession(): boolean {
            return Boolean(
                !this.session.speakeasyType &&
                    !this.session.url &&
                    !this.session.noSession
            );
        },

        pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("sessions");
        },

        speakerFields(): Array<string> {
            return this.pageConfig && this.pageConfig.speakerFields
                ? this.pageConfig.speakerFields
                : ["title", "companyName"];
        },

        sessionDateTime(): Date {
            const iso = this.myStartTime;
            return new Date(iso);
        },

        sessionEarlyAccessMinutes(): Date {
            const session = this.session;
            const returnValue = sessionHelpEarlyAccessMinutes(session);

            return returnValue;
        },

        myStartTime(): Date {
            const returnValue = this.session.startTime;
            return returnValue;
        },

        myEndTime(): Date {
            const returnValue = this.session.endTime;
            return returnValue;
        },

        startTimeAdjusted(): Date {
            const earlyMinutes = this.sessionEarlyAccessMinutes;
            const startTime = this.sessionDateTime;

            return subMinutes(startTime, +earlyMinutes);
        },

        pageRoute(): string | undefined | null {
            return this.$router.currentRoute.name;
        },
        cssVariablesPrimaryLight(): string {
            return store.getters.cssVariables["--primaryLight"];
        }
        /* I can't remember on where and why did I add this to computed properties,
         We need to test sessions with in person and virtual data
         for now we will just comment this.
        */

        // inPerson(): boolean {
        //     return sessionStore.inPerson;
        // }
    },
    /**
     * Life cycle
     */
    created() {
        this.handleActiveSessionCheck();
        this.sessionLabelInterval = window.setInterval(() => {
            this.handleActiveSessionCheck();
        }, 1000);

        this.checkGenericStyle();
        this.screenSize = window.screen.width;
    },

    beforeDestroy() {
        window.clearInterval(this.sessionLabelInterval);
    },

    mounted() {
        // this.getMeetingType(this.sessionId);

        const eL = document.getElementById(this.sessionId) as HTMLElement;

        if (this.pageConfig.truncateDescription) {
            this.isTruncated =
                eL?.offsetHeight < eL?.scrollHeight ? true : false;
        }
    },
    /**
     * Methods
     */
    methods: {
        isHybrid() {
            const session = this.session
            return session.inPerson && session.virtual ? true : false
        },
        toggleClamp(id: string) {
            const eL = document.getElementById(id) as HTMLElement;
            eL.classList.toggle(this.pageConfig.truncateDescription);

            this.isCollapsed = !this.isCollapsed;
        },

        checkGenericStyle() {
            this.overideGenericStyle.color = this.pageConfig.changeDefaultHeaderColor;
        },

        handleActiveSessionCheck() {
            const session = this.session;
            const startDate = this.sessionDateTime;

            this.isSessionActive = sessionHelpSessionIsActive(session);
            this.isSessionOver = sessionHelpSessionIsOver(session);

            this.timeLabel = formatDistance(window.MgServerTime, startDate);
        },

        toggleBiographies() {
            this.showingBios = !this.showingBios;
        },

        goToSession(joinType?: string) {
            const url = this.session.url;

            if (!this.isSessionActive) return;

            if (url) {
                let isUrl = false;
                try {
                    new URL(url);
                    isUrl = true;
                } catch (error) {
                    // no need to log error
                }

                if (isUrl) {
                    window.open(url, undefined, "noopener,noreferrer");
                } else {
                    this.$router.push(url);
                }
            } else if (this.session.speakeasyType) {
                this.$router.push({
                    path: `/meeting/SESSION${this.sessionId}`
                });
            } else {
                const startTime = this.startTimeAdjusted;
                const timeSinceStart = differenceInSeconds(
                    window.MgServerTime,
                    startTime
                );

                this.$router.push({
                    name: "Session",
                    params: {
                        id: this.sessionId,
                        startVideoAt: `${timeSinceStart}`,
                        joinType: joinType as string
                    }
                });
            }
            // this.mutateSessionButtonLoader();
        },

        // mutateSessionButtonLoader(){
        //     this.goToSessionIsClick= false;
        // },

        formattedSpeakerText(speaker: SessionSpeakerObject) {
            const fields = this.speakerFields;

            let data = "";

            const commaFields = [] as Array<string>;

            fields.forEach((field: string) => {
                const key = field as keyof SessionSpeakerObject;

                if (speaker[key]) {
                    commaFields.push(`${speaker[key]}`);
                }
            });

            if (commaFields.length) {
                data += ` &ndash; ${commaFields.join(", ")}`;
            }

            return data;
        },

        getMeetingType(singleSessionId: string) {
            sessionStore.getSingleSession({
                sessionId: singleSessionId,
                isDemoData: false
            });
        }
    }
});
