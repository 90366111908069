<template>
    <svg
        width="14"
        height="19"
        viewBox="0 0 14 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Meeting Location</title>
        <path
            d="M9.70233 15.9567C12.3424 16.1755 14 16.6455 14 17.4248C14 18.423 10.9482 19 7 19C3.05178 19 0 18.423 0 17.4248C0 16.6439 1.66503 16.1732 4.31516 15.9551L4.37266 16.5825C2.19172 16.762 0.66679 17.1934 0.66679 17.4248C0.66679 17.4169 0.670957 17.4256 0.70179 17.4539C0.760125 17.5075 0.860126 17.5681 1.00012 17.6303C1.30596 17.7672 1.76847 17.8971 2.3518 18.0073C3.57014 18.2372 5.22843 18.3702 7.00019 18.3702C8.77195 18.3702 10.4302 18.2372 11.6486 18.0073C12.2319 17.8971 12.6944 17.7673 13.0003 17.6303C13.1403 17.5681 13.2403 17.5075 13.2986 17.4539C13.3294 17.4256 13.3336 17.4169 13.3336 17.4248C13.3336 17.1942 11.8161 16.7644 9.64436 16.5841L9.70233 15.9567ZM7.57146 17.0934C7.25479 17.3933 6.74478 17.3933 6.42811 17.0934C2.37727 13.2542 0.333082 9.68983 0.333082 6.38586C0.333082 2.85921 3.31809 0 6.99985 0C10.6816 0 13.6666 2.85921 13.6666 6.38586C13.6666 9.68983 11.6224 13.2542 7.57159 17.0934H7.57146ZM12.6907 6.38586C12.6907 3.37491 10.1432 0.934463 6.99972 0.934463C3.85621 0.934463 1.30876 3.37471 1.30876 6.38586C1.30876 9.36699 3.19294 12.6827 6.99972 16.3203C10.8065 12.6827 12.6907 9.36699 12.6907 6.38586ZM6.99972 9.03327C5.29386 9.03327 3.91061 7.70842 3.91061 6.07411C3.91061 4.4398 5.29397 3.11494 6.99972 3.11494C8.70548 3.11494 10.0888 4.4398 10.0888 6.07411C10.0888 7.70842 8.70548 9.03327 6.99972 9.03327ZM6.99972 8.09887C8.16725 8.09887 9.11393 7.19202 9.11393 6.07417C9.11393 4.95632 8.16725 4.04947 6.99972 4.04947C5.83219 4.04947 4.88551 4.95632 4.88551 6.07417C4.88551 7.19202 5.83219 8.09887 6.99972 8.09887Z"
            fill="#4D4D4D"
        />
    </svg>
</template>

<script>
export default {
    name: "svg-MeetingLocation"
};
</script>
