<template>
    <svg
        width="23"
        height="17"
        viewBox="0 0 23 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>Virtual</title>
        <path
            d="M9.19773 14.1667V14.1167H9.14773H1.56818C0.731244 14.1167 0.05 13.4943 0.05 12.75V1.41667C0.05 0.672346 0.731244 0.05 1.56818 0.05H21.4318C22.2688 0.05 22.95 0.672346 22.95 1.41667V12.75C22.95 13.4943 22.2688 14.1167 21.4318 14.1167H13.8523H13.8023V14.1667V16.0556V16.1056H13.8523H14.6364C14.9026 16.1056 15.1091 16.299 15.1091 16.5278C15.1091 16.7565 14.9026 16.95 14.6364 16.95H8.36364C8.09742 16.95 7.89091 16.7565 7.89091 16.5278C7.89091 16.299 8.09741 16.1056 8.36364 16.1056H9.14773H9.19773V16.0556V14.1667ZM21.9545 10.4389H22.0045V10.3889V1.41667C22.0045 1.11416 21.7537 0.894444 21.4318 0.894444H1.56818C1.24633 0.894444 0.995454 1.11417 0.995454 1.41667V10.3889V10.4389H1.04545H21.9545ZM1.04545 11.2833H0.995454V11.3333V12.75C0.995454 13.0525 1.24634 13.2722 1.56818 13.2722H21.4318C21.7537 13.2722 22.0045 13.0525 22.0045 12.75V11.3333V11.2833H21.9545H1.04545ZM10.1932 14.1167H10.1432V14.1667V16.0556V16.1056H10.1932H12.8068H12.8568V16.0556V14.1667V14.1167H12.8068H10.1932ZM11.0273 12.2778C11.0273 12.049 11.2338 11.8556 11.5 11.8556C11.7662 11.8556 11.9727 12.049 11.9727 12.2778C11.9727 12.5065 11.7662 12.7 11.5 12.7C11.2338 12.7 11.0273 12.5065 11.0273 12.2778Z"
            fill="#4D4D4D"
            stroke="#808080"
            stroke-width="0.1"
        />
    </svg>
</template>

<script>
export default {
    name: "svg-virtual"
};
</script>
