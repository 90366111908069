





























































import Vue from "vue";
import svgClock from "@/components/svg/svg-clock.vue";
import { getModule } from "vuex-module-decorators";
import { SessionDataObject } from "@/types/interfaces";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import SessionScheduleItem from "@/components/sessions/SessionScheduleItem.vue";
import AdSlot from "@/components/shared/AdSlot.vue";
import eventHub from "@/event-hub";

import attendeeScheduleVuexModule from "@/store/vuex-modules/getAttendeeSchedule";
const attendeeScheduleStore = getModule(attendeeScheduleVuexModule);

const sessionStore = getModule(sessionVuexModule);

export default Vue.extend({
    data() {
        return {
            templateAccessor: "",
            isHoverable: false,
            isLoading: true,
            topicTitle: "",
            isSessionActive: false,
            sessionLabelInterval: 0,
            timeLabel: "",
            isLoadingFavorites: true,
            totalWidth: 0,
            loadClass: true,
            lessTabsThanTabsContainerWidth: true,
            headeroffsetHeight: ""
        };
    },
    components: {
        svgClock,
        SessionScheduleItem,
        AdSlot
    },
    directives: {
        addbuefytabs: {
            bind: function(el) {
                eventHub.$emit("BuefyTabsBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    },
    props: {
        sessionData: {
            type: Object,
            default() {
                return {} as SessionDataObject;
            }
        }
    },
    /**
     * Computed
     */
    computed: {
        cssVariables(): Record<string, string> {
            return this.$store.getters.cssVariables;
        },

        sessionEarlyAccessMinutes(): Date {
            return this.$store.state.settingsVuexModule
                .sessionEarlyEntryMinutes;
        },

        isOndemandData(): boolean {
            const upcomingSessions = sessionStore.sessionData.upcomingSessions;
            const onDemandSessions = sessionStore.sessionData.onDemand;
            const hasUpcomingSessions = Boolean(
                Array.isArray(upcomingSessions) && upcomingSessions.length
            );
            const hasOndemandSessions = Boolean(
                Array.isArray(onDemandSessions) && onDemandSessions.length
            );

            let returnValue = false;

            if (
                (!upcomingSessions || !hasUpcomingSessions) &&
                hasOndemandSessions
            ) {
                returnValue = true;
            }

            return returnValue;
        },

        sortedSession(): Array<SessionDataObject> {
            const sorted = Object.keys(this.sessionData)
                .sort()
                .reduce((result: any, key: any) => {
                    result[key] = this.sessionData[key];
                    return result;
                }, {});

            return sorted;
        }
    },

    /**
     * Lifecycle
     */
    created() {
        const promises = [
            attendeeScheduleStore.getFavoriteSessions(),
            sessionStore.getSessionData({
                isDemoData: false
            })
        ];

        Promise.allSettled(promises).then(() => {
            this.isLoadingFavorites = false;
        });

        if (this.$route?.meta) {
            this.templateAccessor = this.$route.meta.title;
        }
        const eL = document.getElementById("navigation-header") as HTMLElement;
        this.headeroffsetHeight = `${eL.offsetHeight}px`;
    },
    methods: {
        /**
         * Methods
         */

        parseTimeToISO: (timeStart: string, timeEnd: string) => {
            const newTimeS = Vue.prototype.MgFormatISODateTime(
                timeStart,
                "time"
            );
            const newTimeE = Vue.prototype.MgFormatISODateTime(timeEnd, "time");

            return newTimeS + " - " + newTimeE;
        },

        showModal(title: string) {
            this.topicTitle = title;
        }
    },
    watch: {
        "$screen.width"() {
            alert("Width changed");
        }
    }
});
