<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <title>clock</title>
        <path
            fill-rule="evenodd"
            d="M10 0c2.734 0 5.266 1.023 7.111 2.889C18.977 4.755 20 7.289 20 10.023c0 2.733-1.023 5.266-2.889 7.11C15.266 18.979 12.734 20 10 20s-5.266-1.023-7.111-2.866C1.023 15.267 0 12.734 0 10s1.023-5.266 2.866-7.111C4.733 1.023 7.266 0 10 0zm0 1.533c-2.31 0-4.467.866-6.023 2.422-1.555 1.578-2.421 3.71-2.421 6.044 0 2.31.866 4.467 2.421 6.023 1.556 1.555 3.711 2.421 6.023 2.421 2.333 0 4.467-.866 6.044-2.421 1.578-1.556 2.422-3.711 2.422-6.023 0-2.333-.866-4.467-2.444-6.044C14.467 2.4 12.31 1.533 10 1.533zM9.75 4c.407 0 .751.348.751.76v5.11l3.283 3.347c.3.304.28.76-.022 1.065-.15.153-.342.218-.536.218-.193 0-.365-.065-.494-.217L9 10.5V4.76c0-.412.344-.76.751-.76z"
        />
    </svg>
</template>

<script>
export default {
    name: "svg-clock"
};
</script>
