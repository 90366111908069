<template>
    <svg
        width="17"
        height="17"
        viewBox="0 0 17 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title>In Person</title>
        <path
            d="M11.2237 9.27349L11.1274 9.3298L11.2335 9.36421C14.3838 10.386 16.6952 13.0913 16.95 16.3361C16.9495 16.5061 16.8891 16.6724 16.7719 16.782L16.7717 16.7822C16.6518 16.8955 16.5341 16.95 16.354 16.95H0.646006C0.51576 16.95 0.347903 16.948 0.234606 16.7894C0.107169 16.6111 0.0504451 16.5006 0.0500026 16.3361C0.307215 13.0923 2.61888 10.3869 5.70381 9.36411L5.80876 9.32931L5.71332 9.27349C4.17508 8.3738 3.15281 6.81592 3.15281 5.02128C3.15281 2.26854 5.51982 0.05 8.46851 0.05C11.4184 0.05 13.7842 2.26968 13.7842 5.02128C13.7842 6.81592 12.7619 8.3738 11.2237 9.27349ZM1.30953 15.7196L1.29883 15.7785H1.35872H15.5808H15.6393L15.6302 15.7207C15.1083 12.427 12.0463 9.99256 8.46975 9.99256C4.89076 9.99256 1.89585 12.488 1.30953 15.7196ZM8.46975 1.16024C6.18243 1.16024 4.34731 2.87296 4.34731 5.02128C4.34731 7.1696 6.18243 8.88232 8.46975 8.88232C10.7571 8.88232 12.5922 7.1696 12.5922 5.02128C12.5922 2.87296 10.7571 1.16024 8.46975 1.16024Z"
            fill="#4D4D4D"
            stroke="white"
            stroke-width="0.1"
        />
    </svg>
</template>

<script>
export default {
    name: "svg-inPerson"
};
</script>
