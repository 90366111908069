















































































































































































































































import Vue from "vue";
import svgCalendar from "@/components/svg/svg-calendar.vue";
import { getModule } from "vuex-module-decorators";
import { SessionDataObject } from "@/types/interfaces";
import sessionVuexModule from "@/store/vuex-modules/getSessionData";
import layoutImagesVuexModule from "@/store/vuex-modules/layoutImages";
import Spinners from "@/components/utilities/Spinners.vue";
import SessionSchedule from "@/components/schedule/SessionSchedule.vue";
import { format, parseISO } from "date-fns";
import eventHub from "@/event-hub";

import {
    initDomObserverForA11yAttrFixes,
    a11yFixBuefyTabsAriaAttrs,
    a11yFixBuefyTabHrefs,
    a11yFixBuefyNotificationAriaAttrs
} from "@/services/a11y";
import { forEach, isArray } from "lodash";

const layoutImagesStore = getModule(layoutImagesVuexModule);
const sessionStore = getModule(sessionVuexModule);

export default Vue.extend({
    data() {
        return {
            activeTab: 0,
            templateAccessor: "",
            isLoading: true,
            totalWidth: 0,
            loadClass: true,
            lessTabsThanTabsContainerWidth: true,
            buefyTabsObserver: {},
            sessionTypeFilter: "",
            sessionCategoryFilter: "",
            tag3SessionFilter: "",
            searchString: ""
        };
    },
    components: {
        Spinners,
        svgCalendar,
        SessionSchedule
    },
    directives: {
        addbuefytabs: {
            bind: function(el) {
                eventHub.$emit("BuefyTabsBound", el);
            }
        },
        addbuefynotification: {
            bind: function(el) {
                eventHub.$emit("BuefyNotificationBound", el);
            }
        }
    },
    props: {
        noScrollOnDateChange: {
            default: "",
            type: String
        }
    },
    computed: {
        /**
         * Computed
         */
        pageSessionConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("sessions");
        },
        tagsSessionType(): Array<string | undefined> {
            const arr = this.sessionOnSelectedDate
                .filter((category) => {
                    if (this.sessionCategoryFilter && category.tags2) {
                        return category.tags2.includes(
                            this.sessionCategoryFilter
                        );
                    }
                    return category;
                })
                .flatMap((data) => {
                    return data.tags ? data.tags : [];
                })
                .map((item) => {
                    return item?.content;
                });

            return [...new Set(arr)].sort();
        },
        tags2Category(): Array<string | undefined> {
            const arr = this.sessionOnSelectedDate.flatMap((data) => {
                return data.tags2 ? data.tags2 : [];
            });

            return [...new Set(arr)].sort();
        },
        tag3FilterOptions(): Array<string | undefined> {
            const arr = this.sessionOnSelectedDate.flatMap((data) => {
                return data.tags3 ? data.tags3 : [];
            });

            return [...new Set(arr)].sort();
        },
        sessionOnSelectedDate(): Array<SessionDataObject> {
            return this.sessions.filter((data) => {
                return (
                    Vue.prototype.MgFormatISODateTime(
                        data.startTime,
                        "yyyy-mm-dd"
                    ) === this.selectedDay
                );
            });
        },

        ifMultipleTab(): boolean {
            return this.datesForTabs.length > 1 ? true : false;
        },
        cssVariables(): Record<string, string> {
            return this.$store.getters.cssVariables;
        },

        layoutImageSchedule(): string {
            return layoutImagesStore.getLayoutImage("scheduleHeader");
        },

        pageConfig(): Record<string, any> {
            return this.$store.getters.getPageOptions("schedule");
        },

        pageDecorImage(): string {
            let returnValue = "";
            if (
                this.pageConfig.decorImages &&
                this.pageConfig.decorImages.pageBackground
            ) {
                returnValue = `/bg/${this.pageConfig.decorImages.pageBackground}`;
            }
            return returnValue;
        },

        appointmentExtraMinutes(): Date {
            const returnValue = this.$store.state.settingsVuexModule
                .speakeasyExtraMinutes;
            return returnValue;
        },

        backgroundImageStyle(): string {
            let returnValue = "";
            if (this.pageDecorImage) {
                returnValue = `background-image:url(${this.pageDecorImage})`;
            }
            return returnValue;
        },
        isOndemandData(): boolean {
            const upcomingSessions = sessionStore.sessionData.upcomingSessions;
            const onDemandSessions = sessionStore.sessionData.onDemand;
            const hasUpcomingSessions = Boolean(
                Array.isArray(upcomingSessions) && upcomingSessions.length
            );
            const hasOndemandSessions = Boolean(
                Array.isArray(onDemandSessions) && onDemandSessions.length
            );

            let returnValue = false;

            if (
                (!upcomingSessions || !hasUpcomingSessions) &&
                hasOndemandSessions
            ) {
                returnValue = true;
            }

            return returnValue;
        },
        sessions(): Array<SessionDataObject> {
            const upcomingSessions = sessionStore.sessionData.upcomingSessions;
            const onDemandSessions = sessionStore.sessionData.onDemand;
            const hasUpcomingSessions = Boolean(
                Array.isArray(upcomingSessions) && upcomingSessions.length
            );
            let returnValue: Array<SessionDataObject> = [];

            if (hasUpcomingSessions) {
                returnValue = upcomingSessions;
            } else if (this.isOndemandData) {
                const sourceData = onDemandSessions;
                const data = sourceData.filter((item: SessionDataObject) => {
                    return Boolean(!item.onDemandOnly);
                });
                returnValue = data;
            }

            return returnValue;
        },
        datesForTabs(): Array<string> {
            const data = this.sessions;
            let dates: Array<string> = [];
            dates = data
                .filter((item: Record<string, any>) => {
                    return Vue.prototype.MgIsValidISOString(item.startTime);
                })
                .map((item: Record<string, any>) => {
                    const dateFromIso = parseISO(item.startTime || "");
                    const formattedDate = format(dateFromIso, "yyyy-MM-dd");

                    return formattedDate;
                });

            dates = Array.from(new Set(dates)).sort();

            return dates;
        },
        sessionData(): Array<SessionDataObject> {
            if (this.sessions) {
                return this.filterSession(this.sessionOnSelectedDate).reduce(
                    (prev: any, nxt: any) => {
                        const key = `${nxt.startTime}-${nxt.endTime}`;

                        prev[key] = [...(prev[key] || []), nxt];
                        return prev;
                    },
                    {}
                );
            } else {
                return [];
            }
        },

        selectedDay(): string {
            const tabIndex = this.activeTab;
            let returnValue = "";

            if (
                Array.isArray(this.datesForTabs) &&
                this.datesForTabs[tabIndex]
            ) {
                const date = this.datesForTabs[tabIndex];
                if (date) {
                    returnValue = date;
                }
            }

            return returnValue;
        }
    },
    methods: {
        /**
         * Methods
         */
        resetSearch() {
            this.searchString = "";
            this.sessionTypeFilter = "";
            this.sessionCategoryFilter = "";
            this.tag3SessionFilter = "";
        },

        filterSession(data: Array<SessionDataObject>) {
            const fetchedData = data
                .filter((item) => {
                    if (
                        this.sessionCategoryFilter &&
                        Array.isArray(item.tags2)
                    ) {
                        return item.tags2.includes(this.sessionCategoryFilter);
                    } else if (!this.sessionCategoryFilter) {
                        return item;
                    }
                })
                .filter((session) => {
                    if (!this.sessionTypeFilter) {
                        return session;
                    } else if (
                        this.sessionTypeFilter &&
                        Array.isArray(session.tags)
                    ) {
                        return session.tags.some((tag) => {
                            return tag.content.includes(this.sessionTypeFilter);
                        });
                    }
                })
                .filter((tag3Item) => {
                    if (
                        this.tag3SessionFilter &&
                        Array.isArray(tag3Item.tags3)
                    ) {
                        return tag3Item.tags3.includes(this.tag3SessionFilter);
                    } else if (!this.tag3SessionFilter) {
                        return tag3Item;
                    }
                });

            if (this.searchString) {
                return fetchedData.filter((session) => {
                    if (this.searchString) {
                        return (
                            session.title
                                .toLowerCase()
                                .includes(this.searchString.toLowerCase()) ||
                            session.description
                                .toLowerCase()
                                .includes(this.searchString.toLowerCase()) ||
                            this.searchSpeakers(session.speakers)
                        );
                    }
                    return session;
                });
            }

            return fetchedData;
        },
        searchSpeakers(data: any) {
            if (Array.isArray(data)) {
                return data.find((speaker) => {
                    return speaker.name
                        ?.toLowerCase()
                        .includes(this.searchString.toLowerCase());
                });
            }
            return data;
        },

        changeDate(date: string) {
            const query = {
                ...this.$route.query
            };

            query.date = date;

            if (this.noScrollOnDateChange) {
                query.noScroll = "true";
            }

            this.$router.push({
                query: query
            });
        },

        handleBuefyTabsObserver(el: HTMLElement) {
            if (this.buefyTabsObserver instanceof MutationObserver) {
                return;
            }
            this.buefyTabsObserver = initDomObserverForA11yAttrFixes(
                el,
                function() {
                    a11yFixBuefyTabsAriaAttrs(el);
                    a11yFixBuefyTabHrefs(el);
                }
            );
        },

        handleBuefyNotificationFixes(el: HTMLElement) {
            a11yFixBuefyNotificationAriaAttrs(el);
        },

        setScheduleDate(dateString = "") {
            if (Array.isArray(this.datesForTabs)) {
                const today = format(window.MgServerTime, "yyyy-MM-dd");
                let dateToLookFor = today;

                if (dateString) {
                    dateToLookFor = dateString;
                }

                const dateIndex = this.datesForTabs.findIndex((item) => {
                    return dateToLookFor === item;
                });

                if (dateIndex > -1) {
                    this.activeTab = dateIndex;
                }
            }
        }
    },
    watch: {
        /**
         * Watchers
         */

        selectedDay: function() {
            if (this.templateAccessor != "Sessions") {
                return;
            }
            const route = {
                name: "Sessions",
                query: {
                    ...this.$route.query
                }
            };

            route.query.date = this.selectedDay;

            if (this.noScrollOnDateChange) {
                route.query.noScroll = "true";
            }

            this.$router.push(route);
        }
    },

    /**
     * Lifecycle
     */
    created() {
        eventHub.$on("BuefyTabsBound", this.handleBuefyTabsObserver);
        eventHub.$on(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        const promises = [
            sessionStore.getSessionData({
                isDemoData: false
            })
        ];

        Promise.allSettled(promises).then(() => {
            const query = this.$route.query || {};
            let dateToLookFor;
            if (query.date && "string" === typeof query.date) {
                dateToLookFor = query.date;
            }
            this.setScheduleDate(dateToLookFor);

            this.isLoading = false;
        });

        if (this.$route?.meta) {
            this.templateAccessor = this.$route.meta.title;
        }
    },

    updated() {
        const elContainer = document.querySelector(".nested-b-tabs");
        const activeEl = document.querySelector(
            ".nested-b-tabs ul li.is-active"
        ) as HTMLUListElement;

        if (elContainer != null && activeEl != null) {
            this.$nextTick(() => {
                const marginLeft = activeEl.offsetLeft;
                const elWidth = activeEl.offsetWidth;
                const screenSize = screen.width;

                if (screenSize <= 1280) {
                    elContainer.scrollLeft =
                        marginLeft - (screenSize / 2 - elWidth / 2);
                } else if (screenSize < 1980) {
                    elContainer.scrollLeft =
                        marginLeft - screenSize / 2 + elWidth;
                } else if (screenSize <= 2400) {
                    elContainer.scrollLeft = marginLeft - screen.width / 4 - 52;
                } else if (screenSize > marginLeft) {
                    elContainer.scrollLeft =
                        marginLeft - screenSize / 4 + elWidth / 2;
                }
            });
        }
    },

    beforeDestroy() {
        eventHub.$off("BuefyTabsBound", this.handleBuefyTabsObserver);
        eventHub.$off(
            "BuefyNotificationBound",
            this.handleBuefyNotificationFixes
        );

        if (this.buefyTabsObserver instanceof MutationObserver) {
            this.buefyTabsObserver.disconnect();
        }
    }
});
