<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 68 68">
        <title>calendar</title>
        <g fill="" fill-rule="evenodd">
            <path
                d="M17.332.668c-2.293 0-4.168 1.875-4.168 4.168v6.25h8.332v-6.25C21.5 2.543 19.625.668 17.332.668z"
            />
            <path
                d="M54.832 11.082v4.168c0 2.293-1.875 4.168-4.168 4.168s-4.168-1.875-4.168-4.168v-4.168H21.5v4.168c0 2.293-1.875 4.168-4.168 4.168s-4.168-1.875-4.168-4.168v-4.168H.668v56.25h66.668l-.004-56.25h-12.5zM59 59H9V23.582h50V59z"
            />
            <path
                d="M50.668.668c-2.293 0-4.168 1.875-4.168 4.168v6.25h8.332v-6.25c0-2.293-1.875-4.168-4.164-4.168zM29.832 54.418L18.582 43.168 24.418 37.332 29.832 42.75 43.582 29 49.418 34.832z"
            />
        </g>
    </svg>
</template>

<script>
export default {
    name: "svg-calendar"
};
</script>
